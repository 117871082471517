import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import './audio-player.styles.scss';
import 'react-h5-audio-player/lib/styles.css';


const Player = ({ link, name, handlePause, handlePrevious, handleNext }) => (
  <div className="player">
  <AudioPlayer
    autoPlay
    src={link}
    onPause={handlePause}
    onClickPrevious={handlePrevious}
    onClickNext={handleNext}
    onEnded={handleNext}
    showLoopControl={false}
    showSkipControls={true}
    showJumpControls={true}
    header={`Now playing: ${name}`}
    controls
  />
  </div>
);

export default Player;