import React from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';

import './search-box.styles.css';

export const SearchBox = ({ placeholder, handleChange }) => {
    return (
        <Paper component="form" className="container">
            <InputBase
                className="search"
                type="search" 
                placeholder={placeholder} 
                onChange={handleChange} 
            />
        </Paper>
    );
};