import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider } from '@material-ui/core/styles';
import ProductList from './product-list/product-list.component';
import ProductForm from './product-form/product-form.component';
import { SearchBox } from '../search-box/search-box.component';
import { API_URL } from '../../assets/globals';
import theme from '../../assets/theme';
import './product.styles.css';

class Audio extends Component {
    socket: object;
    constructor(props) {
        super(props);
        this.socket = props.socket;
        this.state = {
            products: [],
            searchField: '',
            total: 0,
            projectName: '',
            open: false,
            filter: {},
            showAlert: false,
            errMsg: ''
        };
    }

  // default filters  
  page = 1;
  rowsPerPage = 10;
  total = 0;
  projectId = 0;
  projectName = '';
  order = 'desc';
  orderBy = 'createdAt';
  search = '';

  openDialog() {
    this.setState({ open: true });
  }

  handleClose = (e) => {
    this.setState({ open: false });
  }

  fetchData() {
    try {
        fetch(`${API_URL}/product/list?limit=${this.rowsPerPage}&page=${this.page}&order=${this.order}&orderBy=${this.orderBy}&search=${this.search}&projectid=${this.projectId}`, {
        method: 'get',
        headers: {'Content-Type':'application/json'}
        })
            .then(response => response.json())
            .then(products => {
                this.setState({products: products.data.items, projectName: products.data.project, total: products.data.total, page: products.data.page, pages: products.data.pages, limit: products.data.limit});
                this.projectName = products.data.project;
                this.page = products.data.page-1;
                this.projectId = products.data.projectId;
            });
    } catch (error) {
        console.error(error);
    }
  }

  componentDidMount() {
    this.fetchData();
    this.socket.on('product_list_1', data => {
      if (data.success) {
        this.setState({ products: data.data.items, projectName: data.data.project, total:  data.data.total});
        this.page = data.data.page-1;
      }
    });

    // Event to auto update the product list after CRUD
    this.socket.on('refresh_product_list', data => {
      this.socket.emit('product_list', {
        data: {
          filter: {
            page: (this.page + 1),
            order: this.order,
            orderBy: this.orderBy,
            limit: this.rowsPerPage,
            search: this.search
          }, 
          projectId: parseInt(this.projectId)}
      });
    });

    // update the project name when edited
    this.socket.on('update_project_name', data => {
      if (this.projectId == data.id) {
        this.setState({projectName: data.name})
      }
    });

    this.socket.on('project_deleted', data => {
      if ((this.projectId == data.id) && this.socket.id != data.sid) {
        this.setState({
          showModal: true,
          redirectLink: `/project/`,
          errorText: 'Oops. The project is no longer available. You will now be redirected to the project list.'
        });
      }
    });
  }

  handleChange = (e) => {
    this.search = e.target.value;
    this.fetchData();
  }

  handleFilter = (e) => {
    this.order = e.order;
    this.orderBy = e.orderBy;
    this.rowsPerPage = e.limit;
    this.page = e.page;
    this.search = e.search;
  }
  
  handleChangeRowsPerPage = event => {
    this.rowsPerPage = parseInt(event.target.value, 10);
    this.page = 0;
    this.fetchData();
  };
 
  render() {
    const { products, searchField } = this.state;
    this.projectId = this.props.match.params.id;
    const filteredProducts = products.filter(product => {
        return  product.name.toLowerCase().includes(searchField.toLowerCase()) || 
        product.description.toLowerCase().includes(searchField.toLowerCase())
      }
    );

    return (
      <div className="App">
        <div id="content" className="app-content">
        <ThemeProvider theme={theme}>
          <div className="project-name">
            <h1>{this.state.projectName}</h1>
            <Button onClick={this.openDialog.bind(this)} variant="contained" color="primary">
              Add Product
            </Button>
          <Dialog open={this.state.open} onClose={this.handleClose} maxWidth="lg" fullScreen="true" disableEscapeKeyDown="true">
            <ProductForm
              handleClose={this.handleClose}
              handleAction="add"
              projectId={this.projectId}
              socket={this.socket}
            />
          </Dialog>
        </div>
        <SearchBox
            placeholder="Search Products..."
            handleChange={this.handleChange}
        />
            
            <div>
              <h2>
                  { this.state.searchField === '' ? this.state.total : filteredProducts.length } 
                  &nbsp;Products
              </h2>
              <ProductList 
                  products={filteredProducts}
                  rowsPerPage={this.rowsPerPage}
                  total={this.state.searchField === '' ? this.state.total : filteredProducts.length}
                  onHandleFilter={this.handleFilter}
                  // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  projectId={this.projectId}
                  socket={this.socket}
                  search={this.search}
              />
            </div>
          </ThemeProvider>
        </div>
      </div>
    );
  }

}

export default Audio;