import React from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle,  TextField } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import socketIOClient from 'socket.io-client';
import './project-form.styles.scss';
import { API_URL, SOCKET_ENDPOINT } from '../../../assets/globals';
import { AlertDialog } from '../../alert-dialog/alert-dialog.component';

class ProjectForm extends React.Component {
    handleAction: string;

    constructor() {
        super();
        this.state = {
            id: '',
            name: '',
            description: '',
            error: false,
            errMsg: ''
        };
    }

    componentDidMount() {
        this.handleAction = this.props.handleAction;
        if (this.handleAction === 'update') {
            this.setState({id: this.props.project.id, name: this.props.project.name, description: this.props.project.description}); 
        } 
    }

    addProject = () => {
        this.setState({error: true});
        const { name, description } = this.state;
         fetch(`${API_URL}/project/add`, {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                'name': name,
                'description': description
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            if (data.success) {
                // const socket = socketIOClient.connect(SOCKET_ENDPOINT);
                // socket.emit('update_project_list', {data: {limit: 5}});
                // this.setState({error: false});
                // this.handleClose();
            } else {
                this.setState({error: true, showAlert: true, errMsg: data.message});
                
            }
        })
    }

    editProject = () => {
        const { id, name, description } = this.state;
         fetch(`${API_URL}/project/update/${id}`, {
            method: 'put',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                'name': name,
                'description': description
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                // const socket = socketIOClient.connect(SOCKET_ENDPOINT);
                // socket.on('refresh_project_list', data => {
                    // socket.emit('update_project_list', {data: {limit: 5}});
                // });
            }
        });
    }

    handleClose = () => {
        this.setState({show: false});
    };

    handleChange = (event) => {
        const { value, name } = event.target;

        if (name === 'name' && value === '') {
            this.setState({ error: true });
        } else {
            this.setState({ error: false });
        }

        this.setState({ [name]: value });
    };
 
    handleSubmit = async event => {
        // event.preventDefault();
        const { name } = this.state;
        
        try {
            if (name === '') {
                this.setState({ error: true });
                console.log(this.state);
            } else {
                this.handleAction === 'add' ? await this.addProject() : await this.editProject();
                console.log(this.state);
            }            
        } catch (error) {
            console.error(error);
        }
    };

    hideModal = async (bool, event) => {
        this.setState({showAlert: bool, errMsg: ''});
    }

    capitalize(str: string){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    render() {
        const { handleClose, handleAction } = this.props;
        const { name, description } = this.state;

        return (
            <div>
            <ValidatorForm
                ref="form"
                onSubmit={this.handleSubmit}
                onError={errors => console.error(errors)}
            >
                <DialogTitle id="form-dialog-title">
                    {this.capitalize(handleAction)} Project
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To {handleAction} a project, kindly input the needed information below.
                    </DialogContentText>
                    <TextField
                        label="Project Name"
                        onChange={this.handleChange}
                        name="name"
                        autoFocus
                        margin="normal"
                        id="projectName"
                        fullWidth
                        value={name}
                        required
                    />
                        <div className="error">
                            {this.state.error ? 
                                ( <span>Project Name is required.</span> ) : 
                                ( <span>&nbsp;</span> ) 
                            }
                        </div>
                    <TextField
                        margin="normal"
                        name="description"
                        id="description"
                        label="Description"
                        type="description"
                        value={description}
                        onChange={this.handleChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions className="actions">
                    <Button onClick={handleClose} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={ () => { 
                        this.handleSubmit(); 
                        console.log(this.state);

                        if (!this.state.error && this.state.name !== '') {
                            handleClose();
                        }
                    } } color="primary">
                    {handleAction}
                    </Button>
                </DialogActions>
            </ValidatorForm>
            {
            //     <AlertDialog 
            //     show={this.state.error}
            //     title='Error'
            //     text={this.state.errMsg}
            //     affirmativeText='OK'
            //     onClose={this.hideModal.bind(null, false)}
            // />
            }
            <AlertDialog 
                show={this.state.showAlert}
                title='Error Adding Project'
                text={this.state.errMsg}
                affirmativeText='OK'
                handleClose={this.hideModal.bind(this, false)}
            />
            </div>
            
        );
    }
}

export default ProjectForm;