import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import './modal.styles.css';
  
  export const Modal = ({
    handleClose, 
    handleSubmit, 
    show, 
    title, 
    text,
    affirmativeText,
    cancelText,
    loading,
    processingText
  }) => {
    return (
      <Dialog open={show} onClose={handleClose} disableEscapeKeyDown={loading} disableBackdropClick={loading}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button color="primary" onClick={handleClose} autoFocus disabled={loading}>
            {cancelText}
          </Button>
          { loading ? (
            <div className="loadingDiv">
              <CircularProgress size={15}/>  
              <Button color="primary" onClick={handleClose} autoFocus disabled={loading}>
                {processingText}
              </Button>
            </div>
          ) : (
          <Button color="primary" onClick={handleSubmit} disabled={loading}>
            {affirmativeText}
          </Button>
            )
          } 
        </DialogActions>
      </Dialog>
    );
  };