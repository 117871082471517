import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';

import './input-slider.styles.css'

const useStyles = makeStyles({
  root: {
    width: '28%',
  },
  input: {
    width: 42,
  },
});

export default function InputSlider({ placeholder, defaultValue, handleChange, min, max, step }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(defaultValue);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = event => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  return (
    <div className={classes.root}>
      <Typography id="input-slider" gutterBottom className="placeholder">
        {placeholder}
      </Typography>
      <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            onChangeCommitted={handleChange}
            aria-labelledby="input-slider"
            defaultValue={defaultValue}
            valueLabelDisplay="on"
            min={min}
            max={max}
            step={step}
          />
    </div>
  );
}