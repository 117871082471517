// LOCAL ENVIRONMENT
// export const API_URL = 'http://192.168.0.16:5000/api';      // eve development
// export const SOCKET_ENDPOINT = 'http://192.168.0.16:5000'; // eve development

// TESTING ENVIRONMENT
// export const API_URL = 'http://13.231.191.32/api';
// export const SOCKET_ENDPOINT = 'http://13.231.191.32';

// PRODUCTION ENVIRONMENT
export const API_URL = 'https://audio-comparison.gm-system.net/api';
export const SOCKET_ENDPOINT = 'https://audio-comparison.gm-system.net';