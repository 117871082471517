import React, { Component } from 'react';
import ProjectList from './project-list/project-list.component';
import { SearchBox } from '../search-box/search-box.component';
import { ThemeProvider } from '@material-ui/core/styles';
import { API_URL } from '../../assets/globals';
import theme from '../../assets/theme';
import './project.styles.css';

class Project extends Component {
  socket;
  constructor(props) {
    super(props);
    this.socket = props.socket;
    this.state = {
      projects: [],
      searchField: '',
      total: 0,
    };
  }

  page = 0;
  rowsPerPage = 10;
  total = 0;
  order = 'desc';
  orderBy = 'createdAt';
  search = '';

  fetchData() {
    fetch(`${API_URL}/project/list?limit=${this.rowsPerPage}&page=${this.page+1}&order=${this.order}&orderBy=${this.orderBy}&search=${this.search}`)
    .then(response => response.json())
    .then(projects => {
      this.setState({ projects: projects.data.items, total:  projects.data.total});
    });
  }

  componentDidMount() {
    this.fetchData();

    // Event to auto update the product list after CRUD
    this.socket.on('refresh_project_list', data => {
      this.socket.emit('get_project_list', {
        data: {
          filter: {
            page: (parseInt(this.page, 10) + 1),
            order: this.order,
            orderBy: this.orderBy,
            limit: this.rowsPerPage,
            search: this.search
          }}
      });
    });

    this.socket.on('project_list', projects => {
      if (projects.success) {
        this.setState({ projects: projects.data.items, total:  projects.data.total});
      }
    })
  }

  handleChange = (e) => {
    this.search = e.target.value;
    this.fetchData();
  }

  handleChangePage = (event, newPage) => {
    this.page = newPage;
    this.fetchData();
  };
  
  handleChangeRowsPerPage = event => {
    this.rowsPerPage = parseInt(event.target.value, 10);
    this.page = 0;
    this.fetchData();
  };
  
  handleFilter = (e) => {
    this.order = e.order;
    this.orderBy = e.orderBy;
    this.rowsPerPage = e.limit;
    this.page = e.page;
    this.search = e.search;
  };

  render() {
    // deconstructuring
    const { projects, searchField } = this.state;
    const filteredProjects = projects.filter(project => 
      project.name.toLowerCase().includes(searchField.toLowerCase())
    );

    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <div id="content" className="app-content">
              <SearchBox
                  placeholder="Search Projects..."
                  handleChange={this.handleChange}
              />
              <div>
                  <h1>
                      { this.state.searchField === '' ? this.state.total : filteredProjects.length } 
                      &nbsp;Projects
                  </h1>
                  <ProjectList 
                      projects={filteredProjects}
                      rowsPerPage={this.rowsPerPage}
                      total={this.state.searchField === '' ? this.state.total : filteredProjects.length}
                      search={this.search}
                      onHandleFilter={this.handleFilter}
                      socket={this.socket}
                  />
              </div>
          </div>
        </ThemeProvider>
      </div>
    );
  }
}

export default Project;
