import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
  palette: {
    primary: {
        main: '#28b485',
        contrastText: '#ffffff',
    },
    secondary: {
        main: '#ff5252'
    },
  },
  status: {
    danger: 'orange',
  },
});

export default theme;