import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import ViewList from '@material-ui/icons/ViewList';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Modal } from '../../modal/modal.component';
import { API_URL} from '../../../assets/globals';
import { Link } from 'react-router-dom';
import './product-list.styles.css';

import * as moment from 'moment';

import { Dialog } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import ProductForm from '../product-form/product-form.component';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Product Name' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created Date' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Updated Date' },
  { id: null, numeric: true, disablePadding: true, label: '' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
          {headCell.id != null ? (
            <TableSortLabel
            active={orderBy === headCell.id}
            direction={order}
            onClick={createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
          ) : ''}
            
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    width: '100%',
  },
  nameCol: {
    width: '20%',
  },
  nameDiv: {
    width: '100%'
  },
  rowCol: {
    width: '15%',
  },
  rowDiv: {
    width: '100%'
  },
  actionCol: {
    width: '14%',
  },
  actionDiv: {
    width: '100%'
  },
  descCol: {
    width: '30%',
  },
  descDiv: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '500px'
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function ProductList({ products, rowsPerPage, total, search, onHandleFilter, projectId, socket }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('createdAt');
  const [selected, setSelected] = React.useState([]);
  const [dense] = React.useState(false);
  const [show, setModal] = React.useState(false);
  const [product, setProduct] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [showEdit, setEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  let filter = {
    page: page,
    order: order,
    orderBy: orderBy,
    limit: rowsPerPage
  };

  const showModal = (product) => {
    setModal(true);
    setProduct(product);
  };

  const timezone = moment().utcOffset() / 60;

  const showEditForm = (display, product) => {
    setProduct(product);
    setEdit(display);
    filter = {
      page: (page + 1),
      order: order,
      orderBy: orderBy,
      limit: rowsPerPage,
      search: search
    };
  }

  const handleFilter = () => {
    onHandleFilter(filter);
  }

  const hideModal = () => {
    setModal(false);
  };

  const handleClose = (e) => {
    setEdit(false);
  }

  const handleDelete = () => {
      try {
          const sid =  socket.id;
          setLoading(true);
          fetch(`${API_URL}/product/delete/${product.id}/${sid}`, {
            method: 'put',
            headers: {'Content-Type':'application/json'}
          })
          .then(response => response.json())
          .then(res => {
              if (res.success) {
                setLoading(false);
                setModal(false);
                const newFilter = {
                  page: (page + 1),
                  order: order,
                  orderBy: orderBy,
                  limit: rowsPerPage,
                  search: search
                };
                hideModal();
                socket.emit('product_list', {
                    data: {filter: newFilter, projectId: parseInt(projectId)}
                });
              }
          })
      } catch (error) {
          console.error(error);
      }
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    setPage(0);

    filter = {
      page: 1,
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
      limit: rowsPerPage,
      search: search
    };

    handleFilter();
    socket.emit('product_list', {
        data: {filter: filter, projectId: parseInt(projectId)}
    });

  };

  const handleChangePage = (event, newPage) => {
    filter = {
      page: (newPage + 1),
      order: order,
      orderBy: orderBy,
      limit: rowsPerPage,
      search: search
    };

    setPage(newPage);

    socket.emit('product_list', {
        data: {filter: filter, projectId: parseInt(projectId)}
    });
  };

  const handleChangeRowsPerPage = event => {
    rowsPerPage = parseInt(event.target.value, 10);

    filter = {
      page: 0,
      order: order,
      orderBy: orderBy,
      limit: parseInt(event.target.value, 10),
      search: search
    };

    setPage(0);
    handleFilter();

    socket.emit('product_list', {
        data: {filter: filter, projectId: parseInt(projectId)}
    });

  };

  const isSelected = name => selected.indexOf(name) !== -1;

  return (
    <div>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={products.length}
            />
            <TableBody>
              {products
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={event => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell component="th" id={labelId} scope="row" className={classes.nameCol}>
                        <div className={classes.nameDiv}>
                          <span>{row.name}</span>
                        </div>
                      </TableCell>
                      <TableCell align="left" size="medium" className={classes.descCol}>
                        <div className={classes.descDiv}>
                          <span>{row.description}</span>
                        </div>
                      </TableCell>
                      <TableCell align="left" className={classes.rowCol}>
                        <div className={classes.rowDiv}>
                          <span> { moment(row.createdAt).add(timezone, 'hour').format('YYYY/MM/DD HH:mm') }</span>
                        </div>
                      </TableCell>
                      <TableCell align="left" className={classes.rowCol}>
                      <div className={classes.rowDiv}>
                          <span> { moment(row.updatedAt).add(timezone, 'hour').format('YYYY/MM/DD HH:mm') } </span>
                        </div>
                      
                      </TableCell>
                      <TableCell align="right" className={classes.actionCol}>
                        <div className={classes.actionDiv}>
                          <Link to={`/project/${projectId}/product/${row.id}`}>
                          <IconButton aria-label="view">
                            <ViewList />
                          </IconButton> 
                        </Link>
                          <IconButton aria-label="edit" onClick={showEditForm.bind(null, true, row)}>
                            <EditIcon />
                          </IconButton> 
                          <IconButton aria-label="delete" onClick={showModal.bind(null, row)}>
                            <DeleteIcon />
                          </IconButton> 
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <Dialog open={showEdit} onClose={handleClose} className="dialog" fullWidth="true" maxWidth="md" disableEscapeKeyDown="true" disableBackdropClick="true">
              <ProductForm 
                  show={showEdit} 
                  onClose={handleClose} 
                  handleClose={handleClose} 
                  handleAction="update" 
                  product={product} 
                  projectId={projectId}
                  socket={socket} 
                  filter={filter}
              />          
            </Dialog>

            <Modal 
                show={show}
                handleClose={hideModal}
                handleSubmit={() => {
                  handleDelete(); 
                }}
                title='Confirm Delete'
                text={`Are you sure you want to delete the ${product.name} product?`}
                affirmativeText='Delete'
                cancelText='Cancel'
                onClose={hideModal}
                loading={loading}
                processingText={`Deleting...`}
            />
          </Table>
        </div>
        
        {(total > 10) ? (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : ''
        }
    </div>
  );
}