import React from 'react';
import Button from '@material-ui/core/Button';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import ProjectForm from '../../project/project-form/project-form.component';
import theme from '../../../assets/theme';

import './header-nav.styles.css';

class HeaderNav extends React.Component {
    socket: object;
    state = {
        open: false
    };

     constructor(props) {
        super(props);
        this.socket = props.socket;
     }

    openDialog() {
        this.setState({ open: true });
    }

    handleClose = (e) => {
        this.setState({ open: false });
    }

    render() {
        return (
            <div className="wrapper">
            <ThemeProvider theme={theme}>
                <Typography>
                    <Button onClick={this.openDialog.bind(this)} className="menu">
                        <span className="add-project">Add A Project</span>
                    </Button>
                </Typography>
                <Dialog open={this.state.open} onClose={this.handleClose} className="dialog">
                    <ProjectForm handleClose={this.handleClose} handleAction="add" socket={this.socket} />         
                </Dialog>
            </ThemeProvider>
            </div>
        );
    }
};

export default HeaderNav;