import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import './audio-list.styles.css';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import Warning from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';
import Player from '../audio-player/audio-player.component';

const headCells = [
  { id: null, numeric: false, disablePadding: false, label: '' },
  { id: 'name', numeric: false, disablePadding: false, label: 'File Name' },
  { id: 'originalText', numeric: false, disablePadding: false, label: 'Text' },
  { id: 'length', numeric: false, disablePadding: false, label: 'Length' },
  { id: 'similarity', numeric: false, disablePadding: false, label: 'Similarity' },
  { id: 'lengthError', numeric: true, disablePadding: true, label: 'Length Error' },
];

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 450,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
    // placement: 'bottom-start'
  },
}))(Tooltip);

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
          {headCell.id != null ? (
            <TableSortLabel
            active={orderBy === headCell.id}
            direction={order}
            onClick={createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
          ) : ''}
            
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    width: '100%',
  },
  playCol: {
    width: '8%',
  },
  playDiv: {
    width: '100%'
  },
  nameCol: {
    width: '15%',
  },
  nameDiv: {
    width: '100%'
  },
  rowCol: {
    width: '15%',
  },
  rowDiv: {
    width: '100%'
  },
  descCol: {
    width: '35%',
  },
  descDiv: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '500px'
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function AudioList({ products, rowsPerPage, total, search, onHandleFilter, productId, socket, similarity, lengthError, highestLengthError }) {
  console.log(products);
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [dense] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [audio, setAudio] = React.useState(undefined);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  let filter = {
    page: page,
    order: order,
    orderBy: orderBy,
    limit: rowsPerPage
  };

  const handleFilter = () => {
    onHandleFilter(filter);
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    setPage(0);

    filter = {
      page: 1,
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
      limit: rowsPerPage,
      search: search,
      similarity: similarity,
      lengthError: lengthError
    };
    handleFilter();
    socket.emit('get_audio_list', {
        data: {filter: filter, productId: parseInt(productId)}
    });

  };

  const displayLength = (warning, length) => {
    if (warning !== null) {
      return '-';
    } else {
      return length;
    }
  }

  const displayLengthError = (warning, lengthError) => {
    if (warning !== null) {
      return '-';
    } else {
      return lengthError;
    }
  }

  const handlePlay = (audioFile, i, e) => {
    setAudio(audioFile);
    setSelectedIndex(i);
  }

  const handlePrevious = () => {
    const len = products.length;
    let index = selectedIndex;
    
    if (selectedIndex > 0) {
      index = selectedIndex - 1;
    } else {
      index = len - 1;
    }

    const audioFile = {
      link: products[index].link,
      name: products[index].name
    };

    handlePlay(audioFile, index);
  }

  const handleNext = () => {
    const len = products.length;
    let index = selectedIndex;

    if (selectedIndex < len - 1) {
      index = selectedIndex + 1;
    } else {
      index = 0;
    }

    const audioFile = {
      link: products[index].link,
      name: products[index].name
    };

    handlePlay(audioFile, index);
  }

  const displaySimilarity = (warning, similarity) => {
    if (warning !== null) {
        return '-';
    } else {
        return Math.floor(similarity * 100) + '%';
    }
  }

  const displayButton = (warning, audioFile, i) => {
    if (warning !== null) {
      return (
        <IconButton disabled aria-label="play">
          <Warning color="secondary" />
        </IconButton> 
      );
    } else {
      if (selectedIndex === i) {
        return (
          <IconButton disabled aria-label="play">
            <PlayCircleFilled color="primary" id={audioFile.name} />
          </IconButton> 
        );
      } else {
        return (
          <IconButton aria-label="play" onClick={handlePlay.bind(this, audioFile, i)}>
            <PlayCircleFilled color="primary" id={audioFile.name} />
          </IconButton> 
        );
      }
    }
  }

  const displayTooltip = (warning, recognizedText, originalText) => {
    if (warning !== null) {
      return (
        <p className="error-text">{`This file was listed in CSV but was not included in the upload ZIP file.`}</p>
      )
    } else {
      return (
        <div>
          <p className="recognized-text">{`Recognized Text: ${recognizedText}`}</p>
          <p>{`Original Text: ${originalText}`}</p>
        </div>
      )
    }
  }

  const handleChangePage = (event, newPage) => {
    filter = {
      page: (newPage + 1),
      order: order,
      orderBy: orderBy,
      limit: rowsPerPage,
      search: search,
      similarity: similarity,
      lengthError: lengthError
    };

    setPage(newPage);

    socket.emit('get_audio_list', {
        data: {filter: filter, productId: parseInt(productId)}
    });
  };

  const handleChangeRowsPerPage = event => {
    rowsPerPage = parseInt(event.target.value, 10);

    filter = {
      page: 0,
      order: order,
      orderBy: orderBy,
      limit: parseInt(event.target.value, 10),
      search: search,
      similarity: parseInt(similarity),
      lengthError: parseInt(lengthError)
    };

    setPage(0);
    handleFilter();

    socket.emit('get_audio_list', {
        data: {filter: filter, productId: parseInt(productId)}
    });

  };

  const isSelected = name => selected.indexOf(name) !== -1;


  return (
    <div>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={products.length}
            />
            <TableBody>
              {products
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell component="th" id={labelId} scope="row" className={classes.playCol}>
                        <div className={classes.playDiv}>
                        {
                            displayButton(row.warning,{
                                link: row.link,
                                name: row.name
                              },
                              index)
                        }
                        </div>
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" className={classes.nameCol}>
                        <div className={classes.nameDiv}>
                          <HtmlTooltip placement="bottom-start"
                            title={
                              <React.Fragment>
                                {
                                  displayTooltip(row.warning, row.recognizedText, row.originalText)
                                }
                              </React.Fragment>
                            }>
                            <span>{row.name}</span>
                          </HtmlTooltip>
                        </div>
                      </TableCell>
                      <TableCell align="left" size="medium" className={classes.descCol}>
                        <div className={classes.descDiv}>
                          <span>{row.originalText}</span>
                        </div>
                      </TableCell>
                      <TableCell align="left" className={classes.rowCol}>
                        <div className={classes.rowDiv}>
                          <span> { displayLength(row.warning, row.length) }</span>
                        </div>
                      </TableCell>
                      <TableCell align="left" className={classes.rowCol}>
                        <div className={classes.rowDiv}>
                          <span> { displaySimilarity(row.warning, row.similarity) } </span>
                        </div>
                      </TableCell>
                      <TableCell align="right" className={classes.rowCol}>
                        <div className={classes.rowDiv}>
                          <span> { displayLengthError(row.warning, row.lengthError) } </span>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>

        
        
        {(total > 50) ? (
          <TablePagination
            rowsPerPageOptions={[50, 100, 200, 300]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : ''
        }

         {(audio !== undefined) ? (
          <div className="audio-player">
            <Player 
              link={audio.link}
              name={audio.name}
              handlePrevious={handlePrevious.bind(this)}
              handleNext={handleNext.bind(this)}
            />
          </div>
        ) : ''
        }
    </div>
  );
}