import React from 'react';
import { Button, DialogActions, DialogContent, DialogTitle,  TextField, InputLabel, LinearProgress } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import './product-form.styles.scss';
import { API_URL } from '../../../assets/globals';
import { AlertDialog } from '../../alert-dialog/alert-dialog.component';
import {DropzoneArea} from 'material-ui-dropzone';
import axios from 'axios';

class ProductForm extends React.Component {

    handleAction: string;
    projectId: number;
    socket: object;
    updateProductList;
    filter = {};

    constructor(props) {
        super(props);
        this.socket = props.socket;
        this.filter = props.filter;
        // this.filter.page += 1;
       
        this.updateProductList = props.updateProductList;
        this.state = {
            id: '',
            name: '',
            description: '',
            zipFile: null,
            csvFile: null,
            error: false,
            errMsg: '',
            showAlert: false,
            nameErr: false,
            zipFileErr: false,
            csvFileErr: false,
            uploading: false,
            uploadProgress: 0,
            currentFile: 0,
            totalFiles: 0,
            processProgress: 0,
            success: false
        };
    }

    componentDidMount() {
        this.handleAction = this.props.handleAction;
        this.projectId = Number(this.props.projectId);

        if (this.handleAction === 'update') {
            this.setState({id: this.props.product.id, name: this.props.product.name, description: this.props.product.description}); 
        } else {
            this.setState({id: this.props.projectId});
        }

        this.socket.on('upload_progress', data => {
            this.setState({processProgress: data.percent, currentFile: data.current, totalFiles: data.total})
            if (data.data === 100) {
                this.setState({success: true});
            }
        });
    }

    addProduct = () => {
        this.setState({uploading: true});
        const { id, name, description, zipFile, csvFile } = this.state;
        const formData = new FormData();
        formData.append('projectId', id);
        formData.append('name', name);
        formData.append('description', description);
        formData.append('audio', zipFile[0]);
        formData.append('script', csvFile[0]);
        formData.append('sid', this.socket.id);

        // upload progress
        let config = {
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                this.setState({uploadProgress: percentCompleted});
            },
            timeout: 600000
          };

        axios.post(`${API_URL}/product/add`, formData, config)
        .then(data => {
            console.log(data);
            if (data.data.success) {
                this.setState({show: false, success: true});
            } else {
                this.setState({showAlert: true, errMsg: data.data.message});
            }
        })
    }

    editProduct = () => {
        const { id, name, description } = this.state;
         fetch(`${API_URL}/product/update/${id}`, {
            method: 'put',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                'name': name,
                'description': description
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                this.success = data.success;
                this.setState({successEdit: true});
            }
        });
    }

    handleClose = () => {
        this.setState({show: false});
    };

    handleChange = (event) => {
        const { value, name } = event.target;
        if (name === 'name' && value === '') {
            this.setState({ nameErr: true });
        } else {
            this.setState({ nameErr: false });
        }
        this.setState({ [name]: value });
    };

    handleDrop(type, file) {
        switch (type) {
            case 'csv': 
                this.setState({
                    csvFile: file.length > 0 ? file : null,
                    csvFileErr: false
                });
                break;
            default:
                this.setState({
                    zipFile: file.length > 0 ? file : null,
                    zipFileErr: false
                });
        }

    };

    handleSubmit = async event => {
        // event.preventDefault();
        const { name, zipFile, csvFile } = this.state;

        try {
            if (name === '' || (this.props.handleAction !== 'update' && (zipFile === null || csvFile === null))) {
                this.setState({ showAlert: true, errMsg: 'Please fill out the required fields.' });

                if (name === '') {
                    this.setState({ nameErr: true })
                }

                if (this.props.handleAction !== 'update' && zipFile === null) {
                    this.setState({ zipFileErr: true })
                }

                if (this.props.handleAction !== 'update' && csvFile === null) {
                    this.setState({ csvFileErr: true })
                }
            } else {
                this.handleAction === 'add' ? this.addProduct() : this.editProduct();
            }            
        } catch (error) {
            console.error(error);
        }

    };

    hideModal = async (bool, event) => {
        this.setState({showAlert: bool, errMsg: '', success: bool, uploading: false});
    }

    hideSuccess = async (bool, event) => {
        this.setState({success: bool, show: false});
    }

    capitalize(str: string){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    render() {
        const { handleClose, handleAction } = this.props;
        const { name, description } = this.state;

        return (
            <div className="form-wrapper">
            <ValidatorForm
                ref="form"
                onSubmit={this.handleSubmit}
                onError={errors => console.error(errors)}
            >
                <DialogTitle id="form-dialog-title">
                    {this.capitalize(handleAction)} Product
                </DialogTitle>
                <DialogContent className="dialog-content">
                    <TextField
                        label="Product Name"
                        onChange={this.handleChange}
                        name="name"
                        autoFocus
                        margin="normal"
                        id="productName"
                        fullWidth
                        value={name}
                        required
                    />
                        <div className="error">
                            {this.state.nameErr ? 
                                ( <span>Product Name is required.</span> ) : 
                                ( <span>&nbsp;</span> ) 
                            }
                        </div>
                    <TextField
                        margin="normal"
                        name="description"
                        id="description"
                        label="Description"
                        type="description"
                        value={description}
                        onChange={this.handleChange}
                        fullWidth
                    />
                    { this.props.handleAction !== 'update' ? (
                        <div>
                        <div className="drop-zone">
                        <div className="sub-section">
                            <InputLabel htmlFor="add-files" className="sub-section-label">Compressed Audio File (zip) *</InputLabel>
                            <DropzoneArea 
                                onChange={this.handleDrop.bind(this, 'zip')}
                                filesLimit={1}
                                acceptedFiles={['application/zip', '.zip', 'application/octet-stream', 'application/x-zip-compressed', 'multipart/x-zip']}
                                dropzoneText="You can drag and drop 1 .zip file or choose the file here..."
                                maxFileSize={200000000}
                                showPreviewsInDropzone={true}
                                useChipsForPreview={!this.state.uploading}
                            />
                            <div className="error">
                                {this.state.zipFileErr ? 
                                    ( <span>Compressed Audio files (.zip) is required.</span> ) : 
                                    ( <span>&nbsp;</span> ) 
                                }
                            </div>
                        </div>
                        <div className="sub-section">
                            <InputLabel htmlFor="add-files" className="sub-section-label">Script File (csv) *</InputLabel>
                            <DropzoneArea 
                                onChange={this.handleDrop.bind(this, 'csv')}
                                filesLimit={1}
                                acceptedFiles={[
                                    'text/csv', '.csv', 'text/x-csv', 'application/csv', 'application/vnd.ms-excel', 
                                    'text/comma-separated-values', 'text/x-comma-separated-values', 'text/tab-separated-values']}
                                dropzoneText="You can drag and drop 1 .csv file or choose the file here..."
                                maxFileSize={1000000}
                                showPreviewsInDropzone={true}
                                useChipsForPreview={!this.state.uploading}
                            />
                            <div className="error">
                                {this.state.csvFileErr ? 
                                    ( <span>Script file (.csv) is required.</span> ) : 
                                    ( <span>&nbsp;</span> ) 
                                }
                            </div>
                        </div>
                    </div>
                    <div className="upload-section">
                    { this.state.uploading ? 
                        (
                        <div className="progress-wrapper">
                            <div className="progress">
                                <div className="progress-section">
                                    <InputLabel htmlFor="add-files">Uploading Status</InputLabel>
                                    <InputLabel htmlFor="add-files"> Uploading ({this.state.uploadProgress}%) </InputLabel>
                                </div>
                                <LinearProgress variant="determinate" value={this.state.uploadProgress} />
                            </div>
                            
                            <div className="progress">
                                <div className="progress-section">
                                    <InputLabel htmlFor="add-files">Processing & Recognition Status</InputLabel>
                                    <InputLabel htmlFor="add-files"> Processing {this.state.currentFile} / {this.state.totalFiles} files ({this.state.processProgress}%) </InputLabel> 
                                </div>
                                <LinearProgress variant="determinate" value={this.state.processProgress} />
                            </div>
                        </div>
                        ) :
                            (<span>&nbsp;</span>)
                    }
                    </div>
                    </div>
                    ) : ( '' ) }

                </DialogContent>
                <DialogActions className="actions">
                    <Button onClick={handleClose} color="primary" disabled={this.state.uploading && this.state.processProgress < 100}>
                    Cancel
                    </Button>
                    <Button onClick={ () => {
                        this.handleSubmit(); 
                        if (!this.state.nameErr && this.props.handleAction === 'update') {
                            handleClose()
                        }
                    } } color="primary" disabled={(this.state.uploading && this.state.processProgress < 100)}>
                    {handleAction}
                    </Button>
                </DialogActions>
            </ValidatorForm>
            <AlertDialog 
                show={this.state.showAlert}
                title='Error in Product'
                text={this.state.errMsg}
                affirmativeText='OK'
                handleClose={this.hideModal.bind(this, false)}
            />
            <AlertDialog 
                show={this.state.success}
                title='Successful'
                text={`Recognition is successful. Please check your files.`}
                affirmativeText='OK'
                handleClose={ () => {
                    this.hideSuccess.bind(this, false);
                    handleClose()
                }}
            />
            </div>
            
        );
    }
}

export default ProductForm;