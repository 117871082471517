import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import AudioList from './audio-list/audio-list.component';
import InputSlider from '../input-slider/input-slider.component';
import { SearchBox } from '../search-box/search-box.component';
import { API_URL } from '../../assets/globals';
import theme from '../../assets/theme';
import { Link, Redirect } from 'react-router-dom';
import { Modal } from '../modal/modal.component';
import './audio.styles.css';

class Audio extends Component {
    socket: object;
    constructor(props) {
        super(props);
        this.socket = props.socket;
        this.state = {
            audio: [],
            searchField: '',
            total: 0,
            projectName: '',
            productName: '',
            open: false,
            filter: {},
            page: 0,
            showModal: false,
            redirect: false,
            redirectLink: '',
            errorText: '',
            highestLengthError: 0
        };
    }

  // default filters  
  page = 1;
  rowsPerPage = 50;
  total = 0;
  order = 'desc';
  orderBy = 'name';
  search = '';
  similarity = 95;
  lengthError = 0;
  productId = 0;
  projectName = '';
  projectId = 0;

  openDialog() {
    this.setState({ open: true });
  }

  handleClose = (e) => {
    this.setState({ open: false });
  }

  fetchData() {
    try {
        // console.log(`${API_URL}/audio/list?limit=${this.rowsPerPage}&page=${this.page}&order=${this.order}&orderBy=${this.orderBy}&search=${this.search}&productId=${this.productId}&similarity=${this.similarity}&lengthError=${this.lengthError}`);
        fetch(`${API_URL}/audio/list?limit=${this.rowsPerPage}&page=${this.page}&order=${this.order}&orderBy=${this.orderBy}&search=${this.search}&productId=${this.productId}&similarity=${this.similarity}&lengthError=${this.lengthError}`, {
        method: 'get',
        headers: {'Content-Type':'application/json'}
        })
            .then(response => response.json())
            .then(audio => {
                this.setState({audio: audio.data.items, projectName: audio.data.project, productName: audio.data.product, total: audio.data.total, page: audio.data.page, pages: audio.data.pages, limit: audio.data.limit, highestLengthError: audio.data.highestLengthError});
                this.projectName = audio.data.project;
                this.projectId = audio.data.projectId;
                this.page = audio.data.page;
            });
    } catch (error) {
        console.error(error);
    }
  }

  componentDidMount() {
    this.fetchData();
    this.socket.on('audio_list', data => {
      console.log(data);
      this.setState({ audio: data.data.items, projectName: data.data.project, total:  data.data.total, page: data.data.page});
      this.page = data.data.page;
    });

    // update the product name when edited
    this.socket.on('update_product_name', data => {
      if (parseInt(this.productId) === parseInt(data.id)) {
        this.setState({productName: data.name})
      }
    });

    // update the project name when edited
    this.socket.on('update_project_name', data => {
      if (parseInt(this.projectId) === parseInt(data.id)) {
        this.setState({projectName: data.name})
      }
    });

    this.socket.on('product_deleted', data => {
      if (parseInt(this.productId) === parseInt(data.id) && this.socket.id != data.sid) {
        this.setState({
          showModal: true,
          redirectLink: `/project/${this.projectId}`,
          errorText: 'Oops. The Product of these audio files is no longer available. You will now be redirected to the Product List.'
        });
      }
    });

    this.socket.on('project_deleted', data => {
      if (parseInt(this.projectId) === parseInt(data.id) && this.socket.id != data.sid) {
        this.setState({
          showModal: true,
          redirectLink: `/`,
          errorText: 'Oops. The Project of these audio files is no longer available. You will now be redirected to the Projects List.'
          });
      }
    });
  }

  hideModal = () => {
    this.setState({showModal: false, redirect: true});
  };

  handleChange = (e) => {
    this.search = e.target.value;
    this.fetchData();
  }

  handleFilter = (e) => {
    console.log(e);
    this.order = e.order;
    this.orderBy = e.orderBy;
    this.rowsPerPage = e.limit;
    this.page = e.page;
    this.search = e.search;
    this.similarity = e.similarity;
    this.lengthError = e.lengthError;
    this.fetchData();
  }
  
  handleChangeRowsPerPage = event => {
    this.rowsPerPage = parseInt(event.target.value, 10);
    this.page = 0;
    this.fetchData();
  };

  handleSimilarity = (e, value) => {
    this.similarity = value;
    this.page = 0;
    this.fetchData();
  }

  handleLengthError = (e, value) => {
    this.lengthError = value;
    this.page = 0;
    this.fetchData();
  }
 
  render() {
    const { audio, searchField } = this.state;
    this.productId = this.props.match.params.id;
    const filteredProducts = audio.filter(audio1 => {
        return  audio1.name.toLowerCase().includes(searchField.toLowerCase()) 
      }
    );

    if (this.state.redirect) {
    return <Redirect to={this.state.redirectLink} />
  }

    return (
      <div className="App">
        <div id="content" className="app-content">
        <ThemeProvider theme={theme}>
          <div className="project-name">
            <h1> <Link to={`/project/${this.props.match.params.projectId}`} className="project-link">
                    {this.state.projectName} 
                </Link>&nbsp;
                > {this.state.productName}</h1>
        </div>

        <div className="filter-wrapper">
            <SearchBox
            placeholder="Search Audio Files..."
            handleChange={this.handleChange}
            />
            <InputSlider placeholder="Similarity" defaultValue={95} handleChange={this.handleSimilarity} min={1} max={100} step={1}></InputSlider>
            <InputSlider placeholder="Length Error" defaultValue={0} handleChange={this.handleLengthError} min={0} max={this.state.highestLengthError} step={0.01}></InputSlider>
        </div>
        
            <div>
              <h2>
                Displaying {this.state.searchField === '' ? this.state.total : filteredProducts.length} Audio Files out of {this.state.total}
              </h2>
              <AudioList 
                  products={filteredProducts}
                  rowsPerPage={this.rowsPerPage}
                  total={this.state.searchField === '' ? this.state.total : filteredProducts.length}
                  onHandleFilter={this.handleFilter}
                  productId={this.productId}
                  socket={this.socket}
                  search={this.search}
                  similarity={this.similarity}
                  lengthError={this.lengthError}
              />
            </div>
            <Modal 
                show={this.state.showModal}
                handleClose={this.hideModal}
                handleSubmit={this.hideModal}
                title='Error 404'
                text={`Oops. This product is no longer available. Please go back to Product List.`}
                affirmativeText='OK'
                onClose={this.hideModal}
            />
          </ThemeProvider>
        </div>
      </div>
    );
  }

}

export default Audio;