import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
  } from "@material-ui/core";
  
  export const AlertDialog = ({
    handleClose, 
    show, 
    title, 
    text,
    affirmativeText
  }) => {
    return (
      <Dialog open={show} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose} autoFocus>
            {affirmativeText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };