import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Project from './components/project/project.component';
import Product from './components/product/product.component';
import Audio from './components/audio/audio.component';
import { Header } from './components/header/header.component';
import socketIOClient from 'socket.io-client';
import { SOCKET_ENDPOINT } from './assets/globals';
import './App.css';

class App extends Component {
  test = 'hello there'
  socket = socketIOClient.connect(SOCKET_ENDPOINT);
  
  render() {
    return (
      <div className="App">
        <div id="container">
          <Header />``
        </div>
        {/* <Route exact path ='/' component={Project} /> */}
        {/* <Route exact path ='/projects' component={Project} socket={socket}/> */}
        {/* <Route exact path='/project/:id' component={Product} /> */}
        <Route exact path ='/' render={(props) => <Project {...props} socket={this.socket}/>} />
        <Route exact path ='/projects' render={(props) => <Project {...props} socket={this.socket} />} />
        <Route exact path='/project/:id' render={(props) => <Product {...props} socket={this.socket} />} />
        <Route exact path='/project/:projectId/product/:id' render={(props) => <Audio {...props} socket={this.socket} />} />
      </div>
    );
  }
}

export default App;
