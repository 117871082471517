import React from 'react';
import HeaderNav from './header-nav/header-nav.component';
import { Link } from 'react-router-dom';

import './header.styles.css';

export const Header = () => (
    <div id="header" className="header">
        <Link to={`/`} className="link-name">
            <span id="system-name">Audio Transcription</span>
        </Link>
        <HeaderNav />
    </div>
);